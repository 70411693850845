@import 'src/styles/_variables';

:root {
    --container-width: 1440px;
    --container-secondary-width: 1600px;

    /*fonts*/
    --font-montserratarm-bold: 'Montserratarm-Bold';
    --font-montserratarm-medium: 'Montserratarm-Medium';
    --font-montserratarm-regular: 'Montserratarm-Regular';

    /*colors*/
    --primary-color: #C59163;
    --secondary-color: #4B2F16;
    --tertiary-color: #F9F4EE;
    --white: #FFFFFF;
    --black: #000000;
    --night-rider: #333333;
    --placeholder-color: #9EA3A2;
    --error-color: #FD7542;

    /* Image Tint */
    --white-tint: invert(99%) sepia(6%) saturate(284%) hue-rotate(207deg) brightness(116%) contrast(100%);
    --primary-tint: invert(60%) sepia(10%) saturate(1573%) hue-rotate(347deg) brightness(99%) contrast(94%);
    --secondary-tint: invert(14%) sepia(16%) saturate(2794%) hue-rotate(350deg) brightness(99%) contrast(86%);

    /* Media Queries */
    --desktopL: #{$desktopL};
    --desktopM: #{$desktopM};
    --tabletL: #{$tabletL};
    --tabletM: #{$tabletM};
    --mobileL: #{$mobileL};
    --mobileM: #{$mobileM};
    --mobileS: #{$mobileS};
}

.app {
    background-color: var(--white);

    .app-container {
        position: relative;
        min-height: 100vh;
        margin: auto;
    }

    .tooltip {
        background: var(--black);
        color: var(--white);
        padding: 12px 16px;
        border-radius: 8px;
        max-width: 300px;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
        transition: opacity 0.3s ease-in-out, transform 0.2s ease-in-out;

        .tooltip-info {
            font-family: "Montserratarm-Regular", sans-serif;
            font-size: var(--body-1);
        }
    }

    .tooltip_icon {
        position: absolute;
        top: 16px;
        right: 16px;
        cursor: pointer;
        animation: slideIn 1.5s infinite ease-in-out;

        img {
            width: 22px;
            aspect-ratio: 1;
        }

        &:hover {
            transform: scale(1.1);
            animation: none;
        }
    }
}

/*slick-slide*/
.slick-prev, .slick-next {
    width: 40px !important;
    height: 40px !important;
    background-color: var(--tertiary-color) !important;
    border-radius: 20px !important;

    & img {
        width: 9px !important;
        height: 16px !important;
        position: absolute !important;
        left: 50% !important;
        top: 50% !important;
        transform: translate(-50%, -50%) !important;
        filter: var(--secondary-tint);
    }

    &:hover {
        background-color: var(--tertiary-color) !important;
    }
}

.slick-prev {
    left: -45px !important;

    & img {
        transform: translate(-50%, -50%) rotate(180deg) !important;
    }
}

.slick-next {
    right: -45px !important;
}

.slick-prev::before, .slick-next::before {
    content: "" !important;
}