.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.9);
    position: fixed;
    top: 0;
    z-index: 10;
    width: 100%;

    .lottie {
        width: 200px;
        height: 200px;
    }
}