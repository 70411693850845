.auth {
  width: 100%;
  max-width: var(--container-width);
  padding: 80px 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;

  .auth-image-container {
    width: 50%;
    max-width: 700px;

    .auth-image {
      width: 100%;
      display: flex;
      flex-direction: column;
      row-gap: 10px;
    }
  }

  .form-container {
    width: 50%;

    .title {
      font-family: var(--font-montserratarm-bold);
      font-size: 40px;
      color: var(--night-rider);
      text-align: center;
      margin-bottom: 28px;
    }

    .form {
      width: 324px;
      margin: auto;

      .form-inputs {
        display: flex;
        flex-direction: column;
        row-gap: 8px;
        margin-bottom: 28px;

        .error {
          font-family: var(--font-montserratarm-regular);
          font-size: 10px;
          line-height: 12px;
          color: var(--error-color);
          padding-top: 4px;
        }

        div {
          height: 55px;
        }
      }

      .password-field {
        position: relative;

        .password-toggle-icon {
          position: absolute;
          width: 25px;
          height: 25px;
          right: 2px;
          top: 15%;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: color 0.3s ease;
          cursor: pointer;

          &:hover {
            color: var(--primary-color);
          }

          .eye-icon {
            width: 15px;
            height: 12px;
          }
        }
      }
    }
  }

  .sign-up-info {
    width: 324px;
    margin: 28px auto;
    display: flex;
    flex-direction: column;
    row-gap: 28px;
    font-family: var(--font-montserratarm-regular);
    font-size: 16px;
    color: var(--night-rider);
    text-align: center;

    .text-with-lines::before,
    .text-with-lines::after {
      content: "";
      flex: 1;
      height: 1px;
      background-color: #000;
    }

    .text-with-lines {
      display: flex;
      align-items: center;
    }

    .text-with-lines::before {
      margin-right: 10px;
    }

    .text-with-lines::after {
      margin-left: 10px;
    }
  }

  .forgot-password {
    cursor: pointer;
    transition: color 0.3s ease;

    &:hover {
      transform: scale(1.02);
      opacity: 0.7;
    }
  }

  .auth-text-button {
    color: var(--primary-color);
    padding-left: 4px;
    cursor: pointer;
    transition: color 0.3s ease;

    &:hover {
      opacity: 0.7;
    }
  }

  .confirmation-info {
    font-family: var(--font-montserratarm-regular);
    font-size: 16px;
    color: var(--night-rider);
    text-align: center;
    width: 324px;
    margin: 0 auto 28px;
  }
}

.auth-input {
  font-family: var(--font-montserratarm-regular);
  font-size: 14px;
  width: 324px;
  padding: 11px 8px;
  border: none;
  box-shadow: 0 -1px 0 0 #484747 inset;
}

.auth-input::placeholder {
  font-family: var(--font-montserratarm-regular);
  color: var(--placeholder-color);
}

.auth-button {
  font-family: var(--font-montserratarm-regular);
  font-size: 18px;
  line-height: 22px;
  color: var(--white);
  background-color: var(--primary-color);
  padding: 13px 0;
  width: 324px;
  border-radius: 30px;
  transition: background-color 0.3s ease, transform 0.3s ease;

  &:hover {
    transform: scale(1.02);
    opacity: 0.9;
  }

  &:disabled {
    cursor: unset;
    transform: none;
    opacity: 0.5;
  }
}

.lottie-container {
  position: relative;
  width: 50%;
  height: 100%;

  .email-lottie {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
  }
}

.confirm-code-container {
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100vh;
}

.confirm-code-container > .content {
  overflow: auto;
}

/*--tabletL*/
@media (max-width: 1024px) {
  .auth-image-container {
    display: none;
  }

  .auth {
    display: flex;
    justify-content: center;
  }

  .auth .form-container {
    width: 100%;
  }

  .lottie-container {
    display: none;
  }
}