@import "src/styles/_typography";

.header-mobile {
  --header-mobile-height: 84px;
}

.header-mobile {

  .content {
    position: fixed;
    z-index: 10;
    top: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 8px 0 16px;
    background-color: var(--white);
    height: var(--header-mobile-height);
    transition: transform 0.4s ease-in-out, opacity 0.4s ease-in-out;
    box-shadow: 0 4px 8px 0 #0000001A;

    .header-logo {
      display: flex;
      align-items: center;

      .icon {
        width: 52px;
        filter: var(--secondary-tint);
        cursor: pointer;
      }
    }

    .burger-icon-container {
      text-align: right;
      padding: 8px;
      cursor: pointer;

      .burger-icon {
        width: 24px;
        height: 18px;
        filter: var(--secondary-tint);
      }
    }
  }

  .sidebar {

    .sidebar-overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(28, 24, 39, 0.6);
      z-index: 10;
    }

    .sidebar-content {
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0;
      right: 0;
      z-index: 100;

      .menu {
        overflow-y: auto;
        background: white;
        width: 260px;
        height: 100%;
        position: fixed;
        right: 0;
        transition: 350ms ease-in-out;
        padding: 20px;

        .x-icon-container {
          background-color: var(--tertiary-color);
          width: 44px;
          height: 44px;
          border-radius: 22px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 32px auto 48px;
          cursor: pointer;

          .x-icon {
            width: 14px;
            height: 14px;
            filter: var(--secondary-tint);
          }
        }

        .menu-items {
          display: flex;
          flex-direction: column;
          gap: 40px;
          align-items: center;

          .menu-item {
            @extend .headline5;

            cursor: pointer;
            transition: color 0.3s ease, transform 0.3s ease;

            &:hover {
              transform: scale(1.05);
            }
          }
        }
      }
    }

    .header-mobile-height {
      height: var(--header-mobile-height);
    }
  }

}