@import 'src/styles/_variables';
@import "src/styles/_typography";

.header-desktop, .header-height {
  --header-height: 148px;

  @media (max-width: #{$tabletM}px) {
    --header-height: 84px;
  }
}

.header-desktop {

  height: var(--header-height);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  background-color: var(--white);
  transition: transform 0.4s ease-in-out, opacity 0.4s ease-in-out;
  opacity: 1;

  .section {
    display: flex;
    gap: 24px;
    flex: 1;
    align-items: center;
    justify-content: center;

    .line {
      flex-grow: 1;
      border: 0.5px solid var(--primary-color);
    }

    .category {
      @extend .body3;

      white-space: nowrap;
      cursor: pointer;
      transition: color 0.3s ease, transform 0.3s ease;

      &:hover {
        transform: scale(1.05);
      }
    }
  }

  .logo {
    display: flex;
    align-items: center;
    margin: 0 24px;
    cursor: pointer;

    .logo-icon {
      width: 104px;
      filter: var(--secondary-tint);
    }
  }
}

.header-height {
  height: var(--header-height);
}

@media (max-width: #{$tabletL}px) {
  .header-desktop {
    height: unset;
    justify-content: center;
    padding-top: 5px;

    .header-section {
      gap: 15px;

      .header-line {
        visibility: hidden;
      }
    }

  }
}