/*montserratarm*/
@font-face {
  font-family: "Montserratarm-Bold";
  src: local("montserratarm"),
  url("../main/fonts/montserratarm/Montserratarm-Bold.otf");
}

@font-face {
  font-family: "Montserratarm-Medium";
  src: local("montserratarm"),
  url("../main/fonts/montserratarm/Montserratarm-Medium.otf");
}

@font-face {
  font-family: "Montserratarm-Regular";
  src: local("montserratarm"),
  url("../main/fonts/montserratarm/Montserratarm-Regular.otf");
}

/*trajan-pro*/
@font-face {
  font-family: "Trajan-Regular";
  src: local("trajan-pro"),
  url("../main/fonts/trajan-pro/TrajanPro-Regular.ttf");
}

/* GHEA-Mariam */
@font-face {
  font-family: "GHEA-Mariam-Regular";
  src: local("ghea-mariam"),
  url("../main/fonts/ghea-mariam/GHEAMariamReg.otf");
}

@font-face {
  font-family: "GHEA-Mariam-Bold";
  src: local("ghea-mariam"),
  url("../main/fonts/ghea-mariam/GHEAMariamBld.otf");
}

/* Gastie */
@font-face {
  font-family: "Gastie-Regular";
  src: local("gastie"),
  url("../main/fonts/gastie/Gastie.otf");
}

/* AbrilFatface */
@font-face {
  font-family: "AbrilFatface-Regular";
  src: local("AbrilFatface-Regular"),
  url("../main/fonts/abrilFatface/AbrilFatface-Regular.ttf");
}

/* CreteRound */
@font-face {
  font-family: "CreteRound-Regular";
  src: local("CreteRound-Regular"),
  url("../main/fonts/creteRound/CreteRound-Regular.ttf");
}

/* SHK_Dzeragir */
@font-face {
  font-family: "SHK_Dzeragir";
  src: local("SHK_Dzeragir"),
  url("../main/fonts/shkDzeragir/SHK_Dzeragir.otf");
}

/* Carattere-Regular */
@font-face {
  font-family: "Carattere-Regular";
  src: local("Carattere-Regular"),
  url("../main/fonts/carattere-regular/Carattere-Regular.ttf");
}

/* AlbertSans-VariableFont_wght */
@font-face {
  font-family: "AlbertSans-VariableFont_wght";
  src: local("AlbertSans-VariableFont_wght"),
  url("../main/fonts/albertSans-variableFont_wght/AlbertSans-VariableFont_wght.ttf");
}

/* Aerisa */
@font-face {
  font-family: "Aerisa";
  src: local("Aerisa"),
  url("../main/fonts/aerisa/Aerisa.otf");
}

/* EBGaramond */
@font-face {
  font-family: "EBGaramond-Regular";
  src: local("eb-garamond"),
  url("../main/fonts/eb-garamond/EBGaramond-Regular.ttf");
}

/* MarckScript */
@font-face {
  font-family: "MarckScript-Regular";
  src: local("marckScript"),
  url("../main/fonts/marckScript/MarckScript-Regular.ttf");
}

/* ArshaluyseArtU-Bold */
@font-face {
  font-family: "ArshaluyseArt-Bold";
  src: local("arshaluyseArt"),
  url("../main/fonts/arshaluyseArt/ArshaluyseArtU-Bold.ttf");
}

/* NotoSansArmenian-Regular */
@font-face {
  font-family: "NotoSansArmenian-Regular";
  src: local("noto-sans-armenian"),
  url("../main/fonts/noto-sans-armenian/NotoSansArmenian-Regular.ttf");
}