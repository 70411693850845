@import 'src/styles/_variables';
@import "src/styles/_typography";

.footer {
  --footer-logo-size: 104px;

  @media (max-width: #{$tabletM}px) {
    --footer-logo-size: 72px;
  }
}

.footer {
  width: 100%;
  background-color: var(--tertiary-color);

  .footer-container {
    position: relative;
    max-width: var(--container-width);
    width: 100%;
    height: 100%;
    display: flex;
    column-gap: 100px;
    margin: auto;
    padding: 48px 0;

    .footer-section-title {
      @extend .subtitle3;

      margin-bottom: 8px;
    }

    .footer-payment-section {
      display: flex;
      flex-direction: column;
      gap: 15px;
      flex: 1 1;
      align-items: end;
      justify-content: center;

      .footer-payment-section-container {
        min-width: 190px;
        display: flex;
        flex-direction: column;
        row-gap: 15px;

        .footer-payment-icons {
          display: flex;
          column-gap: 10px;

          .footer-payment-icon {
            width: 50px;
          }
        }

        .footer-languages {
          display: flex;
          column-gap: 15px;
        }

        .footer-language {
          @extend .body3;

          transition: color 0.3s ease, transform 0.3s ease;

          &:hover {
            transform: scale(1.05);
          }
        }

        .footer-current-language {
          font-family: var(--font-montserratarm-bold), sans-serif;
        }
      }

    }

    .footer-logo {
      display: flex;
      align-items: center;

      .footer-logo-icon {
        width: var(--footer-logo-size);
        filter: var(--secondary-tint);
      }
    }

    .footer-contacts-section {
      display: flex;
      flex-direction: column;
      flex: 1 1;
      align-items: start;
      justify-content: center;

      .footer-contacts-section-container {
        display: flex;
        flex-direction: column;
        gap: 15px;
        text-align: end;

        .footer-contact-infos-container {
          display: flex;
          flex-direction: column;
          gap: 10px;

          .footer-contact {
            display: flex;
            align-items: center;
            justify-content: end;
            column-gap: 8px;

            .footer-contact-icon {
              width: 18px;
              height: 18px;
              filter: var(--secondary-tint);
            }

            .footer-contact-info {
              @extend .body3;
            }
          }
        }

        .footer-social-icons {
          display: flex;
          justify-content: end;
          column-gap: 15px;
          margin-top: 10px;

          .footer-social-icon {
            width: 32px;
            height: 32px;
            cursor: pointer;
            filter: var(--secondary-tint);
          }

          a {
            transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
          }
        }
      }
    }
  }

  .footer-copyright {
    background-color: var(--primary-color);
    text-align: center;
    padding: 12px 100px;

    p {
      @extend .subtitle3;

      color: var(--white);
    }
  }
}

@media (max-width: #{$tabletL}px) {
  .footer {

    .footer-container {
      column-gap: 60px;
      max-width: 728px;
      justify-content: center;

      .footer-payment-section {
        align-items: start;
      }

      .footer-contacts-section {
        align-items: end;
      }
    }

  }
}

@media (max-width: #{$tabletM}px) {
  .footer {

    .footer-container {
      flex-direction: column;
      justify-content: space-between;
      padding: 48px;

      .footer-logo {
        justify-content: center;
      }

      .footer-payment-section {
        flex: unset;
        align-items: center;
        margin: 20px 0 24px;

        .footer-payment-section-container {
          align-items: center;
          text-align: center;
          row-gap: 24px;
        }
      }

      .footer-contacts-section {
        flex: unset;
        align-items: center;

        .footer-contacts-section-container {
          text-align: center;
          gap: 24px;

          .footer-contact {
            justify-content: center !important;
          }

          .footer-social-icons {
            justify-content: center;
            column-gap: 8px;
          }
        }
      }
      
      .footer-languages {
        justify-content: center;
      }
    }

    .footer-copyright {
      padding: 12px 5px;
    }

  }
}

@media (max-width: #{$mobileL}) {
  .footer {

    .footer-copyright {
      padding: 12px 5px;
      white-space: break-spaces;
    }

  }
}