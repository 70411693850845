@import 'src/styles/_variables';

:root {
  /*main fonts*/
  --headline1: 40px;
  --headline2: 32px;
  --headline3: 24px;
  --headline4: 20px;
  --headline5: 19px;
  --headline6: 17px;
  --subtitle1: 20px;
  --subtitle2: 18px;
  --subtitle3: 16px;
  --body1: 20px;
  --body2: 18px;
  --body3: 16px;
  --body4: 14px;
  --caption1: 13px;
  --caption2: 11px;
  --btn1: 18px;

  @media (max-width: #{$mobileL}px) {
    --headline1: 32px;
    --headline2: 28px;
    --headline3: 22px;
    --headline4: 20px;
    --headline5: 18px;
    --headline6: 16px;
    --subtitle1: 18px;
    --subtitle2: 16px;
    --subtitle3: 14px;
    --body1: 18px;
    --body2: 16px;
    --body3: 14px;
    --body4: 13px;
    --caption1: 12px;
    --caption2: 10px;
    --btn1: 16px;
  }

  @media (max-width: #{$mobileM}px) {
    --headline1: 30px;
    --headline2: 26px;
  }

  @media (max-width: #{$mobileS}px) {
    --headline1: 28px;
    --headline2: 24px;
  }
}

.headline1 {
  font-family: var(--font-montserratarm-bold), sans-serif;
  font-size: var(--headline1);
  font-weight: 550;
}

.headline2 {
  font-family: var(--font-montserratarm-bold), sans-serif;
  font-size: var(--headline2);
  font-weight: 550;
}

.headline3 {
  font-family: var(--font-montserratarm-medium), sans-serif;
  font-size: var(--headline3);
  font-weight: 600;
}

.headline4 {
  font-family: var(--font-montserratarm-medium), sans-serif;
  font-size: var(--headline4);
  font-weight: 600;
}

.headline5 {
  font-family: var(--font-montserratarm-regular), sans-serif;
  font-size: var(--headline5);
}

.headline6 {
  font-family: var(--font-montserratarm-regular), sans-serif;
  font-size: var(--headline6);
}

.subtitle1 {
  font-family: var(--font-montserratarm-medium), sans-serif;
  font-size: var(--subtitle1);
  font-weight: 550;
}

.subtitle2 {
  font-family: var(--font-montserratarm-medium), sans-serif;
  font-size: var(--subtitle2);
  font-weight: 550;
}

.subtitle3 {
  font-family: var(--font-montserratarm-medium), sans-serif;
  font-size: var(--subtitle3);
  font-weight: 550;
}

.body1 {
  font-family: var(--font-montserratarm-regular), sans-serif;
  font-size: var(--body1);
}

.body2 {
  font-family: var(--font-montserratarm-regular), sans-serif;
  font-size: var(--body2);
}

.body3 {
  font-family: var(--font-montserratarm-regular), sans-serif;
  font-size: var(--body3);
}

.body4 {
  font-family: var(--font-montserratarm-regular), sans-serif;
  font-size: var(--body4);
}

.caption1 {
  font-family: var(--font-montserratarm-regular), sans-serif;
  font-size: var(--caption1);
}

.caption2 {
  font-family: var(--font-montserratarm-regular), sans-serif;
  font-size: var(--caption2);
}

.btn1 {
  font-family: var(--font-montserratarm-medium), sans-serif;
  font-size: var(--btn1);
  font-weight: 550;
}